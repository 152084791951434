<template>
  <div class="container-fluid min-vh-100 d-flex flex-column">
    <div class="row">
      <div class="col">
          <nav class="navbar py-3">
              <div class="container">
                  <a class="navbar-brand" href="#">
                      <img src="@/assets/logo.png" alt="" class="d-inline-block align-text-top">
                  </a>
                  <div>
                      <h5>АСП Шаблон Веб</h5>
                      <p>Система для автоматической проверки шаблонов ФИС ФРДО</p>
                  </div>
              </div>
          </nav>
      </div>
    </div>
    <div class="row flex-grow-1" style="margin-bottom: 100px;">
      <div class="col">
        <div class="container" v-if="stage == 0" style="margin-top: 100px;">
          <div class="file-upload-card">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Шаг 1. Выберите уровень образования</h4>
                <h4 class="card-text">Укажите уровень образования проверяемого файла</h4>

                <div class="select-div">
                  <b-form-select :value="selected_type" class="shadow-none" :options="template_typies" @change="loadDirecties($event)">
                    <template #first>
                      <b-form-select-option :value="null" disabled>Выбрать тип образования</b-form-select-option>
                    </template>
                  </b-form-select>
                </div>
                
                <div v-if="selected_type !== null" style="margin-top: 70px;">
                  <h4 class="card-title">Шаг 2. Загрузите файл с шаблоном</h4>
                  <h4 class="card-text">Прикрепите шаблон который необходимо проверить</h4>

                  <div class="file-drop-area">
                    <span class="fake-btn">
                      <svg xmlns="http://www.w3.org/2000/svg" width="99" height="99" viewBox="0 0 99 99" fill="none">
                        <g filter="url(#filter0_d_305_9089)">
                          <circle cx="49.5" cy="49.5" r="34.5" fill="#498444"/>
                        </g>
                        <path d="M46.8353 61.08H51.2284V52.2284H60.08V47.8353H51.2284V39H46.8353V47.8353H38V52.2284H46.8353V61.08Z" fill="white"/>
                        <defs>
                          <filter id="filter0_d_305_9089" x="0" y="0" width="99" height="99" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_305_9089"/>
                            <feOffset/>
                            <feGaussianBlur stdDeviation="6.5"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0.490196 0 0 0 0 0.686275 0 0 0 0 0.454902 0 0 0 0.4 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_305_9089"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_305_9089" result="shape"/>
                          </filter>
                        </defs>
                      </svg>
                    </span>
                    <span class="file-msg" v-if="sourceFileName != ''">{{ sourceFileName }}</span>
                    <span class="file-msg" v-else>Выберите файл формата xlsx</span>
                    
                    <input 
                      type="file" 
                      :key="fileInputKey"
                      @change="readExcel"
                      class="file-input"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
                  </div>

                  <div class="buttons">
                    <button v-if="sourceFile" @click="checkFile()" class="btn shadow-none">Проверить шаблон</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container" v-else-if="stage == 1">
          <div class="total-header">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-6 p-0">
                    <h5 class="title-1">Всего строк проверено: {{ filteredData.length }}</h5>
                    <h5 class="title-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                        <path d="M10.8075 2.75H22.1925L30.25 10.8075V22.1925L22.1925 30.25H10.8075L2.75 22.1925V10.8075L10.8075 2.75Z" stroke="#F24E1E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M16.5 11V16.5" stroke="#F24E1E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M16.5 22H16.515" stroke="#F24E1E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      Строк с ошибками: {{ errors_count }}
                    </h5>

                    <b-form-checkbox v-model="switchDisplay" name="check-button" switch>
                      <p v-if="switchDisplay">Отобразить ошибки в таблице</p>
                      <p v-else>Отобразить ошибки списком</p>
                    </b-form-checkbox>
                  </div>
                  <div class="col-6">
                    <div class="row" style="justify-content: end;">
                      <button @click="reset()" class="btn shadow-none button-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <g clip-path="url(#clip0_305_9222)">
                          <path d="M30 12H5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M12 19L5 12L12 5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_305_9222">
                          <rect width="24" height="24" fill="white"/>
                          </clipPath>
                          </defs>
                        </svg>
                        Вернуться к выбору шаблона
                      </button>
                    </div>
                    <div class="row" style="justify-content: end;">
                      <div class="button-group">
                        <button @click="exportSheet()" class="btn shadow-none button-1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M5.8335 8.3335L10.0002 12.5002L14.1668 8.3335" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10 12.5V2.5" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                          Исправленный шаблон
                        </button>
                        <button @click="exportErrors()" class="btn shadow-none button-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M5.8335 8.3335L10.0002 12.5002L14.1668 8.3335" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10 12.5V2.5" stroke="#333333" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                          Список ошибок
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="total-content">
            <div v-if="checkedData.length > 0">
              <div v-if="switchDisplay" @click="zoomTable()">
                <b-table-simple hover striped small caption-top responsive style="overflow: hidden;cursor: zoom-in;" >
                  <b-thead head-variant="dark">
                    <b-tr>
                      <b-th>Номер строки</b-th>
                      <b-th v-for="(name, key) in names" :key="key">{{name}}</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr v-for="(data, key) in previewRecords" :key="key">
                      <template v-if="data">
                        <b-td>{{ key + 2 }}</b-td>
                        <b-td v-for="(cell, index) in data" :key="index">
                          {{cell}}
                        </b-td>
                      </template>
                    </b-tr>
                  </b-tbody>                    
                </b-table-simple>
              </div>
              <div v-if="!switchDisplay">
                <ul> 
                  <template v-for="(grouped_error, index) in grouped_errors">
                    <li v-if="grouped_error" :key="index">
                      <p><b>Строка {{ index + 2 }}</b></p>
                      <p v-for="(error, index2) in grouped_error" :key="index2">
                        Столбец {{ names[error.cell] }}: {{ error.message }}
                      </p>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modalProgress" class="modal-progress" size="lg" :no-close-on-backdrop="true" centered hide-footer hide-header ref="modalProgress">
      <h5>Проверка шаблона ФИС ФРДО</h5>
      <p>Пожалуйста, подождите, файл находится в обработке</p>

      <b-progress height="8px" variant="success" :value="progressValue" max="100"></b-progress>
    </b-modal>

    <b-modal id="modalFixedErrors" class="modal-fixed-errors" size="lg" centered hide-footer hide-header ref="modalFixedErrors">
      <h5>
        <span id="tooltip-target-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
            <path d="M18 33C26.2843 33 33 26.2843 33 18C33 9.71573 26.2843 3 18 3C9.71573 3 3 9.71573 3 18C3 26.2843 9.71573 33 18 33Z" stroke="#6D6D6D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18 24V18" stroke="#6D6D6D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M18 12H18.0154" stroke="#6D6D6D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>
        Статистика исправлений
      </h5>
      <b-tooltip target="tooltip-target-1" triggers="hover">
        Программа осуществляет проверку качества заполнения шаблона.<br>
        Достоверность сведений в загруженном шаблоне – зона ответственности пользователя
      </b-tooltip>
      <p class="modal-text">Найдено ошибок: <b>{{ errorsLength }}</b></p>
      <p class="modal-text">Исправлено ошибок: <b>{{ fixed_errors.length }}</b></p>
      <template v-if="fixed_errors.length">
        <a href="#" @click.prevent="" v-b-toggle.collapse-1>Показать список исправленных ошибок</a>
        <b-collapse id="collapse-1" class="mt-2">
          <b-card>
            <ul> 
              <li v-for="(error, index) in fixed_errors" :key="index">
                <p><b>Строка {{ error.row }}</b></p>
                <p>
                  Столбец {{ names[error.cell] }}: {{ error.message }}
                </p>
              </li>
            </ul>
          </b-card>
        </b-collapse>
      </template> 
    </b-modal>

    <b-modal id="zoomModal" class="modal-zoom" size="xl" centered hide-footer hide-header ref="zoomModal">
      <b-table-simple hover striped small caption-top responsive :current-page="currentPage" :per-page="perPage" style="height: 80vH;">
        <b-thead head-variant="dark">
          <b-tr>
            <b-th>Номер строки</b-th>
            <b-th v-for="(name, key) in names" :key="key" :id="'header_' + key" :class="{'invalid': name_errors.includes(key)}">{{name}}</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(data, key) in displayedRecords" :key="key" :class="getRowClasses(key)">
            <template v-if="data">
              <b-td>{{ key + 2 }}</b-td>
              <b-td v-for="(cell, index) in data" :key="index" :class="getCellClasses(key, index)" :id="key + '_' + index">
                {{cell}}
              </b-td>
            </template>
          </b-tr>
        </b-tbody>

        <b-tooltip v-for="error in displayedErrors" :key="error.row + '_' + error.cell" :target="error.row + '_' + error.cell" triggers="hover">
          {{error.message}}
        </b-tooltip>

        <b-tooltip v-for="error in name_errors" :key="'tooltip_header_' + error" :target="'header_' + error" triggers="hover">
          Отличается от названия в шаблоне
        </b-tooltip>
        
      </b-table-simple>

      <b-pagination align="right"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        first-number
        last-number
        pills 
      >

      <template #first-text></template>
      <template #prev-text>
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2324 4.18414C10.4621 4.423 10.4546 4.80282 10.2158 5.0325L7.06557 8L10.2158 10.9675C10.4546 11.1972 10.4621 11.577 10.2324 11.8159C10.0027 12.0547 9.6229 12.0622 9.38404 11.8325L5.78404 8.4325C5.66639 8.31938 5.5999 8.16321 5.5999 8C5.5999 7.83679 5.66639 7.68062 5.78404 7.5675L9.38404 4.1675C9.6229 3.93782 10.0027 3.94527 10.2324 4.18414Z" fill="#626262"/>
          </svg>
          Назад
        </span>
        </template>
      <template #next-text>
        <span>
          Вперед
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.7676 11.8159C5.53792 11.577 5.54537 11.1972 5.78423 10.9675L8.93443 8L5.78423 5.0325C5.54537 4.80282 5.53792 4.423 5.7676 4.18413C5.99727 3.94527 6.3771 3.93782 6.61596 4.1675L10.216 7.5675C10.3336 7.68062 10.4001 7.83679 10.4001 8C10.4001 8.16321 10.3336 8.31938 10.216 8.4325L6.61596 11.8325C6.3771 12.0622 5.99727 12.0547 5.7676 11.8159Z" fill="#626262"/>
          </svg>
        </span>
      </template>
      <template #last-text></template>

      </b-pagination> 
    </b-modal>

    
    <div class="row">
      <div class="col">
        <footer class="footer py-4">
          <div class="container">
            <div class="row">
              <div class="col-6">
                  <p class="callback">Появились вопросы? <a target="_blank" href="mailto:support@uibcom.ru">Написать в техническую поддержку</a></p>
                  <p class="copyright">© 2024 «Управление информационной безопасности»</p>
              </div>
              <div class="col-6">
                  
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>

  </div>

</template>
  
<script>
  
  import * as Excel from '@hyperliskdev/exceljs';
  import FileSaver from 'file-saver';
 
  import * as df from 'dateformat';
  import Auth from '../Auth.js';

  import Swal from 'sweetalert2-neutral';

  export default {
    computed: {
      errorsLength(){
        let length = 0;
        this.grouped_errors.forEach(element => {
          length += element.length;
        });

        return length;
      },
      displayedErrors(){
        let output = [];
        const startIndex = this.perPage * (this.currentPage - 1);
        const endIndex = startIndex + this.perPage;
        this.grouped_errors.forEach((element, index) => {
          if(index >= startIndex && index < endIndex){
            element.forEach((element2) => {
              if(!output.filter(elem => {return elem.row === element2.row && elem.cell === element2.cell}).length){
                output.push(element2);
              }
            });
          }
        });
        return output;
      },
      displayedRecords() {
        const startIndex = this.perPage * (this.currentPage - 1);
        const endIndex = startIndex + this.perPage;
        let output = [];
        this.checkedData.forEach((element, index) => {
          if(index >= startIndex && index < endIndex){
            output[index] = element;
          }
        });
        return output;
      },
      previewRecords() {
        let length = 0;
        if(this.checkedData.length < 5){
          length = this.checkedData.length;
        }else{
          length = 5;
        }
        return this.checkedData.slice(0, length);
      },
      grouped_errors(){
        let output = [];
        this.errors.forEach((item) => {
          if(!output[item.row]){
            output[item.row] = [];
          }
          output[item.row].push(item);
        });
        return output;
      },
      errors_count(){
        let output = [];
        this.errors.forEach((item) => {
          if(!output.includes(item.row)){
            output.push(item.row)
          }
        });
        return output.length;
      }
    },
    data() {
      return {
        token: null, // API Token
        fileInputKey: 0, //file input
        perPage: 8, // Строк на страницу
        totalRows: 1, // Всего строк
        currentPage: 1, // Текущая страница
        switchDisplay: false, // Переключение Таблица - Список
        errorMessage: '', // Текст для модали с ошибкой
        stage: 0,
        progressValue: 0,
        selected_type: null,
        template_typies: [],
        keys: {
          snils: null,
          period: null,
          serial: null,
          number: null,
          type: null,
          middle_name: null
        }, // Ключи столбцов
        names: [], // Названия столбцов из шаблона
        filteredData: [], 
        errors: [], // Массив ошибок в строках
        fixed_errors: [], // Массив исправленных ошибок
        name_errors: [], // Массив ошибок в заголовках столбцов
        checkedData: [],
        valid_names: [],
        valid_lists: [],
        full_numbers: [],
        fileName: '',
        sourceWb: null,
        sourceFile: null,
        sourceFileName: ''
      };
    },
    methods: {
      zoomTable(){
        this.$refs['zoomModal'].show();
      },
      getRowClasses(row){
        var classname = 'hidden';
        this.errors.forEach(error => {
          if(error.row == row){
            classname = '';
          }
        });
        return classname;
      },
      getCellClasses(row, cell){
        var classname = '';
        this.errors.forEach(error => {
          if(error.cell == cell && error.row == row){
            classname = 'invalid';
          }
        });
        return classname;
      },
      showError(message){ //Вывод сообщения с текстом ошибки
        Swal.fire({
          title: "Ошибка!",
          text: message
        });
      },
      checkVersion(){ // Проверка текущей версии справочников
        let version = window.localStorage.getItem('version');
        this.axios.request({
          url: 'https://api.asp.uibcom.ru/api/v1/version',
          method: "GET",
          headers: {
            Authorization: "Bearer " + this.token
          }
        }).then(({data}) => {
          if(!version || version != data.data.version){
            window.localStorage.setItem('version', data.data.version);

            window.localStorage.removeItem('valid_lists_oo');
            window.localStorage.removeItem('valid_names_oo');

            window.localStorage.removeItem('valid_lists_po');
            window.localStorage.removeItem('valid_names_po');

            window.localStorage.removeItem('valid_lists_dpo');
            window.localStorage.removeItem('valid_names_dpo');

            window.localStorage.removeItem('valid_lists_spo');
            window.localStorage.removeItem('valid_names_spo');
          }
        })
      },
      exportErrors(){ // Экспорт ошибок в TXT
        let output = '';
        this.grouped_errors.forEach((grouped_error, index) => {
          output = output + 'Строка ' + (index + 2) + '\n';
          grouped_error.forEach(error => {
            output = output + 'Столбец ' + this.names[error.cell] + ': ' + error.message + '\n';
          }); 
          output = output + '\n';
        });
        const blob = new Blob([output], {type: 'text/plain'});
        FileSaver.saveAs(blob, this.fileName + '_описание_ошибок.txt');
      },
      async exportSheet(){ // Экспорт исправленного пакета
        const sheet = await this.sourceWb.getWorksheet('Шаблон');
        if(this.name_errors && this.name_errors.length >= 1){
          const header = sheet.getRow(1);
          this.name_errors.forEach(name_error => {
            header.getCell(name_error + 1).value = this.valid_names[name_error].name;
          });
        }

        const snils_regex_1 = /^\d+$/;

        // element.type - 1 <-- Ошибка в наличии пробела
        // element.type - 2 <-- Ошибка в СНИЛСе
        // element.type - 3 <-- Ошибка в столбцах утраты обмена уничтожения
        // element.type - 4 <-- Ошибка в Отчетстве
        // element.type - 5 <-- Ошибка в последнем столбце

        this.fixed_errors.splice(0);

        this.errors.forEach(element => {
          if(element.type == 1){
            let cell = sheet.getRow(element.row + 2).getCell(element.cell + 1)
            cell.value = cell.value.trim();

            this.fixed_errors.push({row: element.row + 2, cell: element.cell, message: 'Удалены недопустимые символы'});
          }else if(element.type == 2){
            // -> Попытка исправить СНИЛСы
            const snils = this.checkedData[element.row][element.cell];
            if(snils != null){
              let raw_snils = '';
              snils.split('').forEach(character => {
                if(snils_regex_1.test(character)){
                  raw_snils = raw_snils + '' + character
                }
              });
              if(raw_snils.length == 11){               
                let number = raw_snils;
                let control = number.slice(-2);
                number = number.slice(0, 9);
                if (parseInt(number) > 1001998) {
                  let result = 0;
                  let check_digit = 0;
                  let total = number.length;
                  for (let t = 0; t < total; t++) {
                    result += (total - t) * parseInt(number[t]);
                  }
                  if (result < 100) {
                    check_digit = result;
                  } else if (result > 101) {
                    check_digit = result % 101;
                    if (check_digit === 100) {
                      check_digit = 0;
                    }
                  }
                  if (check_digit == control) {
                    let cell = sheet.getRow(element.row + 2).getCell(element.cell + 1)
                    cell.value = raw_snils.slice(0, 3) + '-' + raw_snils.slice(3,6) + '-' + raw_snils.slice(6,9) + ' ' + raw_snils.slice(-2);

                    this.fixed_errors.push({row: element.row + 2, cell: element.cell, message: 'Исправлено'});
                  }
                }
              }
            }
            
            // <- Попытка исправить СНИЛСы
          }else if(element.type == 3){
            if(this.checkedData[element.row][this.keys.type].includes('Оригинал')){
              let cell = sheet.getRow(element.row + 2).getCell(element.cell + 1);
              cell.value = 'Нет';

              this.fixed_errors.push({row: element.row + 2, cell: element.cell, message: 'Заполнено обязательное поле'});
            }
          }else if(element.type == 4){
            let cell = sheet.getRow(element.row + 2).getCell(element.cell + 1);
            cell.value = 'Нет';

            this.fixed_errors.push({row: element.row + 2, cell: element.cell, message: 'Заполнено обязательное поле'});
          }else if(element.type == 5){
            let cell = sheet.getRow(element.row + 2).getCell(element.cell + 1);
            cell.value = '';

            this.fixed_errors.push({row: element.row + 2, cell: element.cell, message: 'Очищено'});
          }
        });       

        const today = new Date();

        const buffer = await this.sourceWb.xlsx.writeBuffer();
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const blob= new Blob([buffer], {type: fileType});
        FileSaver.saveAs(blob, this.fileName + '_исправленный_' + today.getMinutes() + '_' + today.getSeconds() + EXCEL_EXTENSION);

        this.$refs['modalFixedErrors'].show();
        
      },
      reset(){ // Возврат к выбору файла и очистка переменных
        this.clearData();
        this.stage = 0;
        this.fileInputKey++;
      },
      clearData(){ // Очистка массивов и ключей
        this.names.splice(0);
        this.filteredData.splice(0);
        this.errors.splice(0);
        this.name_errors.splice(0);
        this.checkedData.splice(0);
        this.full_numbers.splice(0);
        this.sourceFile = null;
        this.keys.snils = null;
        this.keys.period = null;
        this.keys.serial = null;
        this.keys.number = null;
        this.keys.type = null;
        this.keys.middle_name = null;
        this.fileName = '';
        this.sourceFileName = '';
        this.progressValue = 0;
      },
      loadDirecties(value){ // Загрузка справочников
        if(value !== this.selected_type){
          this.valid_lists = [];
          this.valid_names = [];
          this.fileInputKey++;
          this.clearData();
        }
        this.selected_type = value;
        if(this.selected_type == 'OO' || this.selected_type == 'PO' || this.selected_type == 'DPO' || this.selected_type == 'SPO'){
          let valid_listsData = null;
          let valid_namesData = null;
          if(this.selected_type == 'OO'){
            valid_listsData = window.localStorage.getItem('valid_lists_oo');
            valid_namesData = window.localStorage.getItem('valid_names_oo');
          }else if(this.selected_type == 'PO'){
            valid_listsData = window.localStorage.getItem('valid_lists_po');
            valid_namesData = window.localStorage.getItem('valid_names_po');
          }else if(this.selected_type == 'DPO'){
            valid_listsData = window.localStorage.getItem('valid_lists_dpo');
            valid_namesData = window.localStorage.getItem('valid_names_dpo');
          }else if(this.selected_type == 'SPO'){
            valid_listsData = window.localStorage.getItem('valid_lists_spo');
            valid_namesData = window.localStorage.getItem('valid_names_spo');
          }

          this.valid_lists = valid_listsData ? JSON.parse(valid_listsData) : [];
          this.valid_names = valid_namesData ? JSON.parse(valid_namesData) : [];

          if(!this.valid_lists.length && !this.valid_names.length){
            this.axios.request({
              url: 'https://api.asp.uibcom.ru/api/v1/directies',
              method: "POST",
              headers: {
                Authorization: "Bearer " + this.token
              },
              params: {
                type: this.selected_type
              }
            }).then(({data}) => {
              this.valid_names = data.names;
              data.lists.forEach(list => {
                this.valid_lists[this.valid_names.findIndex(x => x.name == list.name)] = list;
              });
              if(this.selected_type == 'OO'){
                window.localStorage.setItem('valid_lists_oo', JSON.stringify(this.valid_lists));
                window.localStorage.setItem('valid_names_oo', JSON.stringify(this.valid_names));
              }else if(this.selected_type == 'PO'){
                window.localStorage.setItem('valid_lists_po', JSON.stringify(this.valid_lists));
                window.localStorage.setItem('valid_names_po', JSON.stringify(this.valid_names));
              }else if(this.selected_type == 'DPO'){
                window.localStorage.setItem('valid_lists_dpo', JSON.stringify(this.valid_lists));
                window.localStorage.setItem('valid_names_dpo', JSON.stringify(this.valid_names));
              }else if(this.selected_type == 'SPO'){
                window.localStorage.setItem('valid_lists_spo', JSON.stringify(this.valid_lists));
                window.localStorage.setItem('valid_names_spo', JSON.stringify(this.valid_names));
              }
            })
          }   
        }
      },
      async readFile(file){ // Чтение файла
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsArrayBuffer(file)
          reader.onload = () => {
            resolve(reader.result)
          }
        })
      },
      async checkFile(){ // Проверка файла
        this.$refs['modalProgress'].show();

        if(!this.sourceFile){
          this.$refs['modalProgress'].hide();
          this.showError('Файл не выбран');
          this.reset();
          return;
        }

        if(!this.sourceFile.name.includes('.xlsx')){
          this.$refs['modalProgress'].hide();
          this.showError('Выбери файл формата XLSX');
          this.reset();
          return;
        }

        let buffer  = await this.readFile(this.sourceFile);

        if(!buffer){
          this.$refs['modalProgress'].hide();
          this.showError('Ошибка чтения файла')
          this.reset();
          return;
        }

        const name = this.sourceFile.name;
        const workbook = new Excel.Workbook();
        this.sourceWb = await workbook.xlsx.load(buffer);
        const sheet = await this.sourceWb.getWorksheet('Шаблон');

        if(!sheet){
          this.$refs['modalProgress'].hide();
          this.showError('Выбранный файл не содержит лист «Шаблон». Дальнейшая проверка невозможна');
          this.reset();
          return;
        }

        const regex = /^[А-яA-z0-9\-_.]*$/;

        if(!regex.test(name)){
          let raw_name = '';
          name.split('').forEach(character => {
            if(regex.test(character)){
              raw_name = raw_name + '' + character
            }
          });
          this.fileName = raw_name.slice(0, -5);
        }else{
          this.fileName = name.slice(0, -5);
        }

        const header = sheet.getRow(1);
        let dates = [];

        header.eachCell((cell, colNumber) => {
          this.names.push(cell.value);

          if(cell.value.includes('Срок обучения,')){
            this.keys.period = colNumber - 1;
          }else if(cell.value.includes('СНИЛС')){
            this.keys.snils = colNumber - 1;
          }else if(cell.value.includes('Дата')){
            dates.push(colNumber - 1);
          }else if(cell.value.includes('Серия документа') && !cell.value.includes('Серия документа о ВО/СПО')){
            this.keys.serial = colNumber - 1;
          }else if(cell.value.includes('Номер документа') && !cell.value.includes('Номер документа для изменения') && !cell.value.includes('Номер документа о ВО/СПО')){
            this.keys.number = colNumber - 1;
          }else if(cell.value.includes('Статус документа')){
            this.keys.type = colNumber - 1;
          }else if(cell.value.includes('Отчество получателя') && !cell.value.includes('(оригинала)')){
            this.keys.middle_name = colNumber - 1;
          }                 
        });

        let excelData = [];

        sheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
          if(rowNumber !== 1){
            let rowData = [];
            row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
              if(cell.formula){
                rowData[colNumber - 1] = cell.result
              }else{
                rowData[colNumber - 1] = cell.value
              }
            });

            dates.forEach(date_key => {
              if(rowData[date_key] && new Date(rowData[date_key])){ 
                const date = new Date(rowData[date_key]);
                date.setHours(date.getHours() + 1);
                rowData[date_key] = this.$moment(date).format('DD.MM.YYYY');
              }
            }); 

            if(rowData.filter(el => el != null).length > 1){
              excelData[rowNumber - 2] = rowData;
            }
          }
        });

        if(excelData.length < 1){
          this.$refs['modalProgress'].hide();
          this.showError('Файл не содержит данные')
          this.reset();
          return;
        }

        excelData.forEach((element, index) => {
          if(element.length < this.names.length){
            element = Array.prototype.push.apply(element, Array(this.names.length - element.length).fill(null));
          }
        });

        this.filteredData = excelData;

        this.checkNames(); // Проверка названий столбцов
        this.progressValue = 50;
        this.checkCells().then(() => {
          this.checkedData = this.filteredData;
          this.totalRows = this.filteredData.length;

          this.progressValue = 100;
          this.$refs['modalProgress'].hide();
          this.stage++;
        })

        this.fileInputKey++;
      },
      checkNames(){
        this.names.forEach((name, i) => {
          if(this.valid_names[i] && name != this.valid_names[i].name){
            this.name_errors.push(i);
          }
        });
        if(this.name_errors.length > 1){
          this.showError('Обнаружены ошибки в наименовании столбцов, возможно выбран неверный уровень образования')
        }
      },
      readExcel(event){ // Выбор файла и сохранение его в переменные
        this.clearData();

        if(!event.target.files[0].name.includes('.xlsx')){
          this.showError('Выбери файл формата XLSX');
          this.reset();
          return;
        }

        this.sourceFileName = event.target.files[0].name;
        this.sourceFile = event.target.files[0];
      },
      async checkCells(){ // Проверка ячеек
        let year_start_key = null;
        let year_end_key = null;
        let year_issue_key = null;
        let birthday_key = null;

        if(this.selected_type == 'OO'){
          year_start_key = this.valid_names.findIndex(x => x.name == "Год поступления");
          year_end_key = this.valid_names.findIndex(x => x.name == "Год окончания");
          year_issue_key = this.valid_names.findIndex(x => x.name == "Дата выдачи");
        }else if(this.selected_type == 'PO'){
          year_start_key = this.valid_names.findIndex(x => x.name == "Год начала обучения");
          year_end_key = this.valid_names.findIndex(x => x.name == "Год окончания обучения");
          year_issue_key = this.valid_names.findIndex(x => x.name == "Дата выдачи документа");
        }else if(this.selected_type == 'DPO'){
          year_start_key = this.valid_names.findIndex(x => x.name == "Год начала обучения (для документа о квалификации)");
          year_end_key = this.valid_names.findIndex(x => x.name == "Год окончания обучения (для документа о квалификации)");
          year_issue_key = this.valid_names.findIndex(x => x.name == "Дата выдачи документа");
        }else if(this.selected_type == 'SPO'){
          year_start_key = this.valid_names.findIndex(x => x.name == "Год поступления");
          year_end_key = this.valid_names.findIndex(x => x.name == "Год окончания");
          year_issue_key = this.valid_names.findIndex(x => x.name == "Дата выдачи");
        }

        birthday_key = this.valid_names.findIndex(x => x.name == "Дата рождения получателя");

        let last_column_key = this.valid_names.findIndex(x => x.name == "Номер документа для изменения");
        
        this.filteredData.forEach((row, row_key) => {
          // Проверка последнего столбца
          if(last_column_key != -1 && row[last_column_key] !== null){
            this.errors.push({type: 5, row: row_key, cell: last_column_key, message: 'Номер документа для изменения запрещено заполнять'});
          }
          // !Проверка последнего столбца

          // Проверка Даты рождения
          if(row[birthday_key] && this.$moment(row[birthday_key], "DD.MM.YYYY") > this.$moment(new Date())){
            this.errors.push({row: row_key, cell: birthday_key, message: 'Дата рождения не может быть позже текущей даты'});
          }
          if(row[birthday_key] && this.$moment(new Date()).format('YYYY') - this.$moment(row[birthday_key], "DD.MM.YYYY").format('YYYY') > 99){
            this.errors.push({row: row_key, cell: birthday_key, message: 'Дата рождения неверное значение'});
          }
          // !Проверка Даты рождения

          // Проверка на совпадение номеров и серий
          if(!this.full_numbers.includes(row[this.keys.serial] + "" + row[this.keys.number])){
            this.full_numbers.push(row[this.keys.serial] + "" + row[this.keys.number]);
          }else{
            this.errors.push({row: row_key, cell: this.keys.serial, message: 'Найдено совпадение'});
            this.errors.push({row: row_key, cell: this.keys.number, message: 'Найдено совпадение'});
          }
          // !Проверка на совпадение номеров и серий

          // Проверка что номер число
          if(row[this.keys.number] && !this.isInt(row[this.keys.number])){
            this.errors.push({row: row_key, cell: this.keys.number, message: 'Содержит недопустимые символы. Номер должен содержать только цифры'});
          }
          // !Проверка что номер число

          // Проверка СНИЛС
          if(row[this.keys.snils]){
            this.checkSNILS(row[this.keys.snils], row_key, this.keys.snils);
          }
          // !Проверка СНИЛС

          // Проверка срока начала и окончания обучения
          let year_start = this.$moment(row[year_start_key], "YYYY").format('YYYY');
          let year_end = this.$moment(row[year_end_key], "YYYY").format('YYYY');
          if(this.$moment(row[year_start_key], "YYYY", true).isValid() && this.$moment(row[year_end_key], "YYYY", true).isValid() && year_end < year_start){
            this.errors.push({row: row_key, cell: year_start_key, message: 'Срок поступления не может быть позже срока окончания'});
            this.errors.push({row: row_key, cell: year_end_key, message: 'Срок поступления не может быть позже срока окончания'});
          }
          if(this.$moment(row[year_issue_key], "DD.MM.YYYY") > this.$moment(new Date())){
            this.errors.push({row: row_key, cell: year_issue_key, message: 'Дата выдачи не может быть позже текущей даты'});
          }
          let year_issue = this.$moment(row[year_issue_key], "DD.MM.YYYY").format('YYYY');
          if(row[this.keys.type] == "Оригинал"){
            if(this.$moment(row[year_issue_key], "DD.MM.YYYY", true).isValid() && this.$moment(row[year_start_key], "YYYY", true).isValid() && year_issue < year_start){
              this.errors.push({row: row_key, cell: year_issue_key, message: 'Дата выдачи не может быть ранее Года поступления'});
            }
            if(this.$moment(row[year_issue_key], "DD.MM.YYYY", true).isValid() && this.$moment(row[year_end_key], "YYYY", true).isValid() && year_issue < year_end){
              this.errors.push({row: row_key, cell: year_issue_key, message: 'Дата выдачи не может быть ранее Года окончания'});
            }
            if(row[this.keys.type + 1] && row[this.keys.type + 1] != "Нет"){
              this.errors.push({row: row_key, cell: this.keys.type + 1, message: 'Не соответствует статусу документа Оригинал'});
            }
            if(row[this.keys.type + 2] && row[this.keys.type + 2] != "Нет"){
              this.errors.push({row: row_key, cell: this.keys.type + 2, message: 'Не соответствует статусу документа Оригинал'});
            }
            if(row[this.keys.type + 3] && row[this.keys.type + 3] != "Нет"){
              this.errors.push({row: row_key, cell: this.keys.type + 3, message: 'Не соответствует статусу документа Оригинал'});
            }
          }

          if(row[this.keys.type] == "Дубликат" && row[this.keys.type + 1] == "Нет" && row[this.keys.type + 2] == "Нет" && row[this.keys.type + 3] == "Нет"){
            this.errors.push({row: row_key, cell: this.keys.type, message: 'Выберите одну из причин выдачи дубликата'});
          }
          // !Проверка срока начала и окончания обучения

          // Проверка для ДПО Вид документа Свидетельство
          if(this.selected_type == 'DPO'){
            let type_doc_key = this.valid_names.findIndex(x => x.name == "Вид документа");

            if(row[type_doc_key] === 'Удостоверение о повышении квалификации' && row[this.keys.period] < 16){
              this.errors.push({row: row_key, cell: this.keys.period, message: 'Для Удостоверения о повышении квалификации Срок обучения не менее 16-ти часов'});
            }else if(row[type_doc_key] === 'Свидетельство о повышении квалификации' && row[this.keys.period] < 16){
              this.errors.push({row: row_key, cell: this.keys.period, message: 'Для Свидетельства о повышении квалификации Срок обучения не менее 16-ти часов'});
            }else if(row[type_doc_key] === 'Диплом о профессиональной переподготовке' && row[this.keys.period] < 250){
              this.errors.push({row: row_key, cell: this.keys.period, message: 'Для Диплома о профессиональной переподготовке Срок обучения не менее 250-ти часов'});
            }

            if(row[type_doc_key] === 'Свидетельство о повышении квалификации' && year_issue > 2013){
              this.errors.push({row: row_key, cell: type_doc_key, message: 'Вид документа Свидетельство о повышении квалификации принимается до 01.01.2014'});
            }
            let type_2_key = this.valid_names.findIndex(x => x.name == "Дополнительная профессиональная программа (повышение квалификации/ профессиональная переподготовка)");
            if(row[type_doc_key] === 'Диплом о профессиональной переподготовке' && row[type_2_key] !== null && row[type_2_key] !== 'Профессиональная переподготовка'){
              this.errors.push({row: row_key, cell: type_doc_key, message: 'Вид документа не соответствует дополнительной профессиональной программе'});
            }else if(row[type_doc_key] === 'Свидетельство о повышении квалификации' && row[type_2_key] !== null && row[type_2_key] !== 'Повышение квалификации'){
              this.errors.push({row: row_key, cell: type_doc_key, message: 'Вид документа не соответствует дополнительной профессиональной программе'});
            }else if(row[type_doc_key] === 'Удостоверение о повышении квалификации' && row[type_2_key] !== null && row[type_2_key] !== 'Повышение квалификации'){
              this.errors.push({row: row_key, cell: type_doc_key, message: 'Вид документа не соответствует дополнительной профессиональной программе'});
            }
          }
          // !Проверка для ДПО Вид документа Свидетельство
          
          row.forEach((cell, cell_key) => {
            // Проверка на обязательные поля
            if(this.valid_names[cell_key] && this.valid_names[cell_key].required && cell == null){
              if(cell_key > this.keys.type && cell_key < this.keys.type + 4){
                this.errors.push({type: 3, row: row_key, cell: cell_key, message: 'Не заполнено обязательное поле'});
              }else if(cell_key == this.keys.middle_name){
                this.errors.push({type: 4, row: row_key, cell: cell_key, message: 'Не заполнено обязательное поле'});
              }else if(this.valid_lists[cell_key]){
                this.errors.push({type: 6, row: row_key, cell: cell_key, message: 'Не заполнено обязательное поле'});
              }else{
                this.errors.push({row: row_key, cell: cell_key, message: 'Не заполнено обязательное поле'});
              }
            }
            // !Проверка на обязательные поля

            if(this.valid_names[cell_key] && this.valid_names[cell_key].requiredif){
              this.valid_names[cell_key].conditions.forEach(condition => {
                if(eval("row[this.valid_names.findIndex(x => x.name == condition.field_name)]" + condition.apperand + "condition.field_value") && !cell){
                  // Проверка на обязательные поля
                  if(cell_key == this.keys.snils && year_issue < 2021){
                    return;
                  }
                  this.errors.push({row: row_key, cell: cell_key, message: 'Не заполнено обязательное поле'});
                }
              });
            }

            // Проверка выпадающих списков
            if(this.valid_lists[cell_key] && cell){
              if(!this.valid_lists[cell_key].values.includes(cell.toString())){
                this.errors.push({type: 6, row: row_key, cell: cell_key, message: 'Неверное значение'});
              }
            }
            // !Проверка выпадающих списков

            // Проверка Пробелов
            if(cell !== null && typeof(cell) === "string" && cell.trim() !== cell){
              this.errors.push({type: 1, row: row_key, cell: cell_key, message: 'Содержит недопустимые символы'});
            }
            // !Проверка Пробелов
          });
        });
      },
      isInt(value) {
        return !isNaN(value) && parseInt(Number(value)) == value && !isNaN(parseInt(value, 10));
      },
      checkSNILS(snils, row, cell){ // Проверка СНИЛСа
        const regex = /^(\d{3}-){2}\d{3} \d{2}$/gm;  
        if(!regex.test(snils)){
          this.errors.push({type: 2, row: row, cell: cell, message: 'Неверный формат СНИЛСа'});
        }else{
          let number = snils.replace(/ |-/g, '');
          let control = number.slice(-2);
          number = number.slice(0, 9);
          if (parseInt(number) < 1001998) {
            this.errors.push({row: row, cell: cell, message: 'Введён некорректный СНИЛС'});
          } else {
            let result = 0;
            let check_digit = 0;
            let total = number.length;
            for (let t = 0; t < total; t++) {
              result += (total - t) * parseInt(number[t]);
            }
            if (result < 100) {
              check_digit = result;
            } else if (result > 101) {
              check_digit = result % 101;
              if (check_digit === 100) {
                check_digit = 0;
              }
            }
            if (check_digit != control) {
              this.errors.push({row: row, cell: cell, message: 'Поле не прошло проверку. Пожалуйста проверьте введёный СНИЛС'});
            }
          }
        }
      }
    },
    mounted() {
      // localStorage.clear();
      if(Auth.check()){
        this.token = window.localStorage.getItem('token');
        let modules = window.localStorage.getItem('modules');
        let available_modules = modules ? JSON.parse(modules) : null;

        if(available_modules.includes(187)){
          this.template_typies.push({ value: 'OO', text: 'Общее образование'});
        }
        if(available_modules.includes(222)){
          this.template_typies.push({ value: 'PO', text: 'Профессиональное обучение'},);
        }
        if(available_modules.includes(819)){
          this.template_typies.push({ value: 'DPO', text: 'Дополнительное профессиональное образование'});
        }
        if(available_modules.includes(401)){
          this.template_typies.push({ value: 'SPO', text: 'Среднее профессиональное образование'});
        }

        this.checkVersion();
      }
    }
  };
</script>
  
<style>
  .file-upload-card .card-body{
        padding-left: 69px;
        padding-top: 38px;
        padding-right: 69px;
        padding-bottom: 48px;

        border-radius: 20px;
        border: 2px solid #F0F0F0;
        background: #FFF;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .file-upload-card .card-body h4.card-title{
      color: var(--greyscale-greyscale-100, #111);
      font-family: Gilroy;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px; /* 100% */

      margin-bottom: 16px;
    }

    .file-upload-card .card-body h4.card-text{
      color: var(--greyscale-greyscale-70, #6D6D6D);
      font-family: Gilroy;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 110% */

      margin-bottom: 32px;
    }

    .file-drop-area {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      padding-top: 67px;
      padding-bottom: 67px;

      border-radius: 12px;
      border: 1px dashed rgba(0, 0, 0, 0.40);
      background: #FDF9F9;
      backdrop-filter: blur(75px);
    }

    .file-msg {
      color: #7F7F88;
      text-align: center;
      font-family: Gilroy;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .file-input {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      cursor: pointer;
      opacity: 0;
    }

    .swal2-container .swal2-modal h2.swal2-title{
      color: #F24E1E;
      font-family: Gilroy;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 42px; /* 116.667% */
      letter-spacing: -1px;
    }

    .swal2-container .swal2-modal .swal2-html-container{
      color: var(--Neutral-Dark-Dark-1, #292A33);
      font-family: Gilroy;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 150% */
      letter-spacing: -0.3px;
    }

    .swal2-container .swal2-modal .swal2-actions .swal2-confirm{
      padding: 16px 24px;
      border-radius: 20px;
      background: var(--new, #59A054);
    }

    .swal2-popup{
      border-radius: 5px;
      border: 1px solid var(--Neutral-Light-Light-2, #F4F5F7);
      background: var(--Neutral-Light-Light-1, #FFF);

      /* Elevation 1 */
      box-shadow: 0px 4px 8px -2px rgba(19, 25, 35, 0.10), 0px 1px 2px -1px rgba(19, 25, 35, 0.10), 0px -1px 2px 0px rgba(19, 25, 35, 0.06), 0px 0px 1px 0px #C2C9D4;

      color: #FFF;
      text-align: center;
      font-family: Gilroy;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 100% */
    }

    .file-upload-card .buttons{
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .file-upload-card .buttons .btn{
      margin-top: 48px;

      padding: 16px 24px;
      border-radius: 20px;
      background: var(--new, #59A054);

      color: #FFF;
      text-align: center;
      font-family: Gilroy;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 100% */
    }

    #modalProgress .modal-dialog .modal-content{
      border-radius: 20px;
      background: #FFF;
      padding: 60px 76px;
    }

    #modalProgress .modal-dialog .modal-content h5{
      color: var(--greyscale-greyscale-100, #111);
      text-align: center;
      font-family: Gilroy;
      font-size: 34px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      margin-bottom: 10px;
    }

    #modalProgress .modal-dialog .modal-content p{
      color: var(--greyscale-greyscale-70, #6D6D6D);
      text-align: center;
      font-family: Gilroy;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      margin-bottom: 45px;
    }

    .total-header .title-1{
      color: #333;
      font-family: Gilroy;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      margin-bottom: 12px;
    }

    .total-header .title-2{
      color: #F24E1E;
      font-family: Gilroy;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      margin-bottom: 34px;
    }

    .total-header .button-3{
      border-radius: 15px;
      border: 1px dashed #7F7F88;
      padding: 10px 20px;

      align-self: baseline;

      color: #333;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Gilroy;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 66.667% */

      margin-bottom: 34px;
    }

    .total-header .button-2{
      border-radius: 15px;
      border: 1px solid #000;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)!important;

      padding: 10px 20px;

      align-self: baseline;

      color: #333;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Gilroy;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px; /* 111.111% */      
    }

    .total-header .button-1{
      border-radius: 15px;
      background: var(--new, #59A054);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)!important;

      padding: 10px 20px;

      align-self: baseline;

      color: #FFF;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Gilroy;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px; /* 111.111% */

      margin-right: 27px;
    }

    .total-header .custom-control.custom-switch label{
      color: #333;
      font-family: Gilroy;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 100% */
    }

    .total-content .table-responsive{
      border-radius: 5px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .modal-xl{
      max-width: 99vW!important;
      max-height: 99vH!important;
    }

    #zoomModal .modal-content{
      border-radius: 0!important;
    }

    #zoomModal .modal-content .modal-body{
      padding: 0!important;
    }

    .invalid{
      background-color: #F24E1E;
      color: #fff;
    }

    .file-upload-card select::-ms-expand {
      display: none;
    }

    .file-upload-card select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      /* Add some styling */
      
      display: block;
      width: 100%;
      height: 70px;

      padding: 11px 56px 11px 24px;

      border-radius: 10px;
      border: 1px solid #DACFCF;
      background: #FDF9F9;

      color: #7F7F88;
      font-family: Gilroy;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 110% */
    }

    .file-upload-card .select-div{
      position: relative;
    }

    .file-upload-card .select-div:after {
      content: "";
      
      background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHZpZXdCb3g9IjAgMCA1MCA1MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9ImFycm93Ij4KPHBhdGggaWQ9IlBhdGgiIGQ9Ik05LjM3NSAxOC43NUwyNSAzMS4yNUw0MC42MjUgMTguNzUiIHN0cm9rZT0iIzdGN0Y4OCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjwvZz4KPC9zdmc+Cg==") no-repeat;
      background-position: right 5px top 50%;

      width: 50px;
      height: 50px;


      pointer-events: none;

      top: 10px;
      right: 20px;
      
      position: absolute;
    }

    .custom-switch .custom-control-label::after, .custom-switch .custom-control-input:checked ~ .custom-control-label::after{
      background-color: #59A054!important;
    }

    .custom-control-input:checked ~ .custom-control-label::before, .custom-switch .custom-control-label::before{
      background-color: #E1E7EF!important;
      border-color: #E1E7EF!important;
      
    }

    .custom-switch .custom-control-label::before {
      box-shadow: none;
    }

    #modalFixedErrors .modal-body h5{
      color: #292A33;
      font-family: Gilroy;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 42px; /* 105% */

      margin-bottom: 22px;
    }

    #modalFixedErrors .modal-body .modal-text{
      color: var(--Neutral-Dark-Dark-1, #292A33);
      font-family: Gilroy;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 83.333% */
      letter-spacing: -0.3px;

      margin-bottom: 26px;
    }

    #modalFixedErrors .modal-body a{
      color: var(--new, #59A054);
      font-family: Gilroy;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 111.111% */
      letter-spacing: -0.3px;
      text-decoration-line: underline;
    }

    #modalFixedErrors .modal-content{
      border: unset;
      background: none;
    }

    #modalFixedErrors .modal-content .modal-body{
      border-radius: 20px;
      border: 1px solid var(--Neutral-Light-Light-2, #F4F5F7);
      background: var(--Neutral-Light-Light-1, #FFF);

      /* Elevation 1 */
      box-shadow: 0px 4px 8px -2px rgba(19, 25, 35, 0.10), 0px 1px 2px -1px rgba(19, 25, 35, 0.10), 0px -1px 2px 0px rgba(19, 25, 35, 0.06), 0px 0px 1px 0px #C2C9D4;

      padding: 50px 60px 50px 60px;
    }

    #modalFixedErrors .modal-content .modal-body .card .card-body{
      padding: 20px 0 0 0;
    }

    #modalFixedErrors .modal-content .modal-body .card .card-body ul{
      padding-left: 20px;
      margin-bottom: 0;
    }

    .b-pagination-pills{
      padding-right:15px;
    }

    .b-pagination-pills .page-item .page-link {
      border-radius: 4px!important;
      border: 1px solid var(--colors-grey-4-border, #E9E9E9);
      background: var(--colors-White, #FFF);

      padding: 8px 12px;

      color: var(--colors-black-1-primary-text, #313131);

      /* text/Text base */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */

      box-shadow: none;
    }

    .b-pagination-pills .page-item.active .page-link{
      border-radius: 4px;
      background: #353A40;

      color: var(--colors-White, #FFF);

      /* text/Text base bold */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 142.857% */
    }

    .b-pagination-pills .page-item.disabled .page-link{
      opacity: 0.5;
    }
    
</style>
  