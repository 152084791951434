<template>
    <div class="container-fluid min-vh-100 d-flex flex-column login-page">
        <div class="row">
            <div class="col">
                <nav class="navbar py-3">
                    <div class="container">
                        <a class="navbar-brand" href="#">
                            <img src="@/assets/logo.png" alt="" class="d-inline-block align-text-top">
                        </a>
                        <div>
                            <h5>АСП Шаблон Веб</h5>
                            <p>Система для автоматической проверки шаблонов ФИС ФРДО</p>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
        <div class="row flex-grow-1">
            <div class="col d-flex align-items-center">
                <div class="container">
                    <div class="login-card" style="max-width: 70%;">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title">Вход в систему</h4>
                                <h4 class="card-text">Пожалуйста, авторизуйтесь чтобы продолжить</h4>

                                <form class="auth-login-form" @submit.prevent="login()">

                                    <div class="input-group">
                                        <span class="input-group-text">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <g opacity="0.3">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 5.25L2.25 4.5H21.75L22.5 5.25V18.75L21.75 19.5H2.25L1.5 18.75V5.25ZM3 6.8025V18H21V6.804L12.465 13.35H11.55L3 6.8025ZM19.545 6H4.455L12 11.8035L19.545 6Z" fill="#333333"/>
                                                </g>
                                            </svg>
                                        </span>
                                        <input @change="clearErrors()" type="text" class="form-control shadow-none" v-model="loginform.email" placeholder="Электронная почта" tabindex="1" required autofocus />
                                    </div>

                                    <div class="input-group" :class="{ 'is-invalid': errors.password && errors.password.length > 0 }">
                                        <span class="input-group-text">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <g opacity="0.3">
                                                    <path d="M20 12C20 10.897 19.103 10 18 10H17V7C17 4.243 14.757 2 12 2C9.243 2 7 4.243 7 7V10H6C4.897 10 4 10.897 4 12V20C4 21.103 4.897 22 6 22H18C19.103 22 20 21.103 20 20V12ZM9 7C9 5.346 10.346 4 12 4C13.654 4 15 5.346 15 7V10H9V7Z" fill="#333333"/>
                                                </g>
                                            </svg>
                                        </span>
                                        <input @change="clearErrors()" type="password" class="form-control shadow-none" v-model="loginform.password" tabindex="2" placeholder="Пароль" required />
                                    </div>

                                    <div v-if="errors.password && errors.password.length > 0" class="invalid-feedback d-block mb-4">
                                        {{ errors.password[0] }}
                                    </div>
                                    
                                    <button type="submit" class="btn shadow-none" tabindex="4">Войти</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <footer class="footer py-3">
                    <div class="container">
                        <div class="row">
                            <div class="col-6">
                                <p class="callback">Появились вопросы? <a target="_blank" href="mailto:support@uibcom.ru">Написать в техническую поддержку</a></p>
                                <p class="copyright">© 2024 «Управление информационной безопасности»</p>
                            </div>
                            <div class="col-6">
                                
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    </div>
</template>
  
  
<script>
import Auth from '../Auth.js';
import { Form, HasError, AlertError } from 'vform';
import router from '../Routes.js';

export default {
    data () {
        return {
            loginform: new Form({
                email: null,
                password: null
            }),
            errors: {
                email: [],
                password: []
            }
        }
    },
    methods: {
        clearErrors(){
            this.errors.email.splice(0)
            this.errors.password.splice(0)
        },
        login(){
            this.loginform.post('https://api.asp.uibcom.ru/api/v1/login')
            .then(({data})=> {
                if(data.success){
                    Auth.login(data.data.access_token, data.data.modules);
                    window.location.href = '/';
                }
            })
            .catch(({response})=>{
                this.errors = response.data.errors;
            })
        }
    },
    mounted() {
      if(Auth.check()){
        router.push({ name: 'ValidationPage' })
      }else{
        if(this.$route.query.email && this.$route.query.password){
            this.loginform.email = this.$route.query.email;
            this.loginform.password = this.$route.query.password;
            this.$router.replace('/login');
            this.login();
        }
      }
    }
}
</script>
  

<style>
@import url('https://fonts.googleapis.com/css?family=Inter');
@import url('../fonts/stylesheet.css');

    .col{
        padding: 0;
    }

    p, h5, h4{
        margin: 0;
    }

    .card{
        border: unset;
        background: none;
    }

    .navbar .container, .navbar .container-fluid{
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: left;
    }

    .navbar .navbar-brand{
        width: 59px;
        height: 49px;
    }

    .navbar h5{
        color: #000;
        font-family: Gilroy;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px; /* 130% */
    }
    .navbar p{
        color: #000;
        font-family: Gilroy;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .footer{
        background: #F5F5F5;
    }

    .footer .callback{
        color: #333;
        font-family: Gilroy;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 120% */

        margin-bottom: 18px;
    }

    .footer .callback a{
        color: #37993B;
        font-family: Gilroy;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-decoration-line: underline;
    }

    .footer .copyright{
        color: #333;
        font-family: Gilroy;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }

    .login-card .card-body{
        padding-left: 69px;
        padding-top: 48px;
        padding-right: 69px;
        padding-bottom: 48px;

        border-radius: 25px;
        background: rgba(245, 245, 245, 0.90);
    }

    .login-card .card-body h4.card-title{
        color: #000;
        font-family: Gilroy;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        margin-bottom: 14px;
    }

    .login-card .card-body h4.card-text{
        color: #333;
        font-family: Gilroy;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        margin-bottom: 40px;
    }

    .login-card .card-body .btn{
        width: 100%;
        padding-top: 22px;
        padding-bottom: 20px;
        border-radius: 30px;
        background: var(--new, #59A054);

        color: #FFF;
        font-family: Gilroy;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .login-card .card-body .input-group{
        border-radius: 15px;
        border: 1px solid #DACFCF;
        background: #FFF;

        padding: 10px 10px;

        margin-bottom: 35px;
    }

    .login-card .card-body .input-group span, .login-card .card-body .input-group input{
        background: none;
        border: unset;

        color: #333;
        font-family: Gilroy;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .login-page{
        background: url('@/assets/login_back.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 100% 50%;
    }

</style>