import axios from 'axios';

class Auth {
    constructor () {
        this.token = window.localStorage.getItem('token');
        if (this.token) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
        }
    }
    login (token, modules) {
        window.localStorage.setItem('token', token);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

        this.token = token;

        window.localStorage.setItem('modules', JSON.stringify(modules));

        this.modules = modules;
    }
    check () {
        return !! this.token;
    }
    logout () {
        window.localStorage.removeItem('token');
    }
}
export default new Auth();
