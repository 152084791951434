import Vue from 'vue';
import Auth from './Auth.js';
import VueRouter from 'vue-router';

import ValidationPage from '@/components/ValidationPage'
import LoginPage from '@/components/LoginPage'

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'ValidationPage',
        component: ValidationPage,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/login',
        name: 'LoginPage',
        component: LoginPage,
        meta: {
            requiresAuth: false,
        },
    }
];

const router = new VueRouter({
     mode: 'history',
     routes: routes
 });

 router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)){
    if (Auth.check()) {
      next();
      return;
    }else{
      window.location.href = '/login';
    }
  }else{
    next();
  }
});

export default router;
